<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">Consider the sketch of a TLC plate shown below:</p>

      <p class="mb-5 pl-8">
        <v-img :src="imageName" max-width="231px" />
      </p>

      <p class="mb-3">
        Calculate the value of the retention factor,
        <stemble-latex content="$\text{R}_\text{f},$" />
        for
        <stemble-latex :content="spotToAnalyze" />
        Use 2 significant figures in your answer.
      </p>

      <calculation-input
        v-model="inputs.studentAnswer"
        prepend-text="$\text{R}_\text{f}:$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemUCI51lbA5_Q13',
  components: {
    ChemicalLatex,
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
      },
    };
  },
  computed: {
    plateNumber() {
      return this.taskState.getValueBySymbol('plateNumber').content ?? 3;
    },
    spotNumber() {
      return this.taskState.getValueBySymbol('spotNumber').content ?? 3;
    },
    imageName() {
      return '/img/assignments/TLC_Rf_' + this.plateNumber.value + '.png';
    },
    spotToAnalyze() {
      if (this.spotNumber.value === 1) {
        return '$\\text{Compound A.}$';
      } else if (this.spotNumber.value === 2) {
        return '$\\text{Compound B.}$';
      } else {
        return '$\\text{Compound C.}$';
      }
    },
  },
};
</script>
